import { FunctionComponent } from "react";
import EccoviaLogo from '../../Shared/images/eccovia-logo-transparent.png';
import { FeedbackRequestType } from "../../Shared/Models/FeedbackRequestType";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FeedbackFormInputs } from "../../Shared/Models/FeedbackFormInputs";

interface FeedbackFormProps {
    onSubmit: (e: any) => void;
    username: string | undefined;
    email: string | undefined;
    entityId: string | undefined
}

const FeedbackForm: FunctionComponent<FeedbackFormProps> = (props: FeedbackFormProps) => {
    const  { onSubmit, username, email, entityId } = props;
    const { register, handleSubmit, formState: { errors }, getValues } = useForm<FeedbackFormInputs>({ mode: "onChange" });

    const navigate = useNavigate();

    const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate(-1);
    }

    return (
        <div className="card auth-card bg-light">
        <div className="card-body p-0">
           <div className="row align-items-center auth-content">
              <div className="col-lg-7 align-self-center">
                 <div className="p-3">
                    <h3 className="mb-2 text-center text-primary">Feedback Form</h3>
                    <div className="justify-content-around align-items-center mt-3 w-100">
                       <form onSubmit={handleSubmit(onSubmit)} method="POST" role="form">
                           <input type="hidden" defaultValue={ entityId } {...register("entityId")} />
                           <div className="form-row mb-2">
                               <div className="col">
                                   <label>Date</label>
                                   <input type="text" className="form-control" name="date" defaultValue={new Date().toLocaleDateString()} readOnly/>
                               </div>
                           </div>
                           <div className="form-row mb-2">
                               <div className="col">
                                   <label>Username</label>
                                   <input type="text" className="form-control" defaultValue={username} readOnly {...register("username") }/>
                               </div>
                           </div>
                           <div className="form-row mb-2">
                               <div className="col">
                                   <label>Email</label>
                                   <input type="text" className="form-control" defaultValue={email} readOnly {...register("email")}/>
                               </div>
                           </div>
                           <div className="form-row mb-2">
                               <div className="col">
                                   <label htmlFor="organizationInput">Organization</label>
                                   <input type="text" id="organizationInput" className="form-control" defaultValue={''} {...register("organization", { required: true })} />
                                    { errors.organization && <span className="text-danger">Organization is required.</span> }
                               </div>
                           </div>
                           <div className="form-row mb-2">
                               <div className="col">
                                   <label htmlFor="feedbackTypeOptions">Request Type</label>
                                   <select className="form-control" id="feedbackTypeOptions" {...register("feedbackType")}>
                                       <option value={FeedbackRequestType.Issue}>Report an Issue</option>
                                       <option value={FeedbackRequestType.Suggestion}>Suggest an Improvement</option>
                                       <option value={FeedbackRequestType.Question}>Ask a Question</option>
                                       <option value={FeedbackRequestType.UATFeedback}>Leave a Comment</option>
                                   </select>
                               </div>
                           </div>
                           <div className="form-row mb-2">
                               <div className="col">
                                   <label htmlFor="feedbackDetails">Add Details Here:</label>
                                   <textarea id="feedbackDetails" rows={3} className="form-control" {...register("details", { required: true, minLength: 20 }) }/>
                                   { errors.details && <span className='text-danger'>Details at least 20 chars in length is required.</span>}
                               </div>
                           </div>
                           <div className="justify-content-center">
                               <div className="mt-2 mb-4 w-100">
                                   <button className="btn btn-primary w-100" type="submit">Send Feedback</button>
                                   <button className="btn btn-light w-100" onClick={handleCancel}>Cancel</button> 
                               </div>
                           </div>
                       </form>
                    </div>
                 </div>
              </div>
              <div className="col-lg-5 content-right">
                 <img src={EccoviaLogo} className="img-fluid image-right" alt="Eccovia Inc." />
              </div>
           </div>
        </div>
     </div>
    )
}

export default FeedbackForm;