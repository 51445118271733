import { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import { FeedbackEntity } from "../../Shared/Models/FeedbackEntity";
import { FeedbackRequestType } from "../../Shared/Models/FeedbackRequestType";

interface FeedbackHistoryProps {
    histories: FeedbackEntity[]
}

const FeedbackHistory: FunctionComponent<FeedbackHistoryProps> = (props) => {
    const { histories } = props;

    return (
        <>
            <div className="d-flex">
                <h3 className="mr-2">ClientEmpower Feedback</h3>
                <NavLink to={"new"} className="btn btn-primary" data-testid="newfeedback-btn">
                    <span>
                    <i className="fa fa-plus p-1gi" aria-hidden="true"></i>
                    </span>
                </NavLink>
            </div>
            { histories?.length > 0 ?
                <>
                    <div className="table-responsive pr-2">
                    <table id="user-list-table" className="table table-striped dataTable mt-4" role="grid" aria-describedby="user-list-page-info">
                        <thead>
                            <tr className="ligth">
                                <th>Id</th>
                                <th>Submitted On</th>
                                <th>Type</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {histories?.map((feedback: FeedbackEntity, index: number) => (
                                <tr key={index}>
                                <td>{feedback.id}</td>
                                <td>{feedback.dateSubmitted}</td>
                                <td>{FeedbackRequestType[feedback.feedbackType]}</td>
                                <td>
                                    {feedback.details}
                                </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                    
            </>
            :
            <div className="d-flex justify-content-center">
                <p>You have not submitted any feedback.</p>
            </div>
        }
        </>
    );
}

export default FeedbackHistory;